// @flow
import { AssignmentTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: AssignmentTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: AssignmentTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAssignmentDetail = (params) => {
    return {
        type: AssignmentTypes.GET_ASSIGNMENT_DETAIL,
        payload: { params },
    };
};
export const updateAssignmentFile = (id, data) => ({
    type: AssignmentTypes.UPDATE_ASSIGNMENT_FILE,
    payload: { id, data },
});

export const createFileAssignments = (params) => ({
    type: AssignmentTypes.CREATE_ASSIGNMENT_FILE,
    payload: { params },
});

export const resetAssigment = () => ({
    type: AssignmentTypes.RESET,
    payload: {},
});
