import {Col, Layout, Row} from 'antd';
import './HeaderContent.scss';
import React, {useEffect} from 'react';
import TopMenu from '../top-menu/TopMenu';
import NotiGroup from '../noti-group/NotiGroup';
import DrawerLeft from '../drawer/Drawer';
import Logo from '../logo/Logo';
import {Redirect, useLocation} from "react-router-dom";
import {ExclamationCircleFilled} from '@ant-design/icons';
import {useSelector} from "react-redux";
import config from "../../config";
import {notShow} from "../../utils/array";


const HeaderContent = ({menu}) => {
    const route = useLocation()
    const {user} = useSelector((state) => state.Auth);
    let newMenu = []
    const webOnline = process.env["REACT_APP_URL_WEB_ONLINE"]
    const domainCurrent = window.location.href
    if (domainCurrent.search(webOnline) === -1) {
        newMenu = menu
    } else {
        menu.map(item => {
            if (item.link.search('roadmap') === -1) {
                newMenu.push(item)
            }
        })
    }
    const isShowAlert = () => {
        if(!notShow()) return ;
        if (!user) return false
        if (!user.user.id) return false
        if (user.user.is_active !== config.statusActive) return true
        if (user.user.is_active === config.statusActive) return false
    }

    useEffect(() => {
        if (domainCurrent.search(webOnline) !== -1) {
            if (route.pathname.search('roadmap') !== -1) {
                return <Redirect to={{pathname: '/'}}/>
            }
        }
    })


    return (<>
        <Layout className="layout">
            <Row wrap={false} className="col-drawer-no-wrap ant-row-drawer top-header">
                <Logo/>
                <Col>
                    <DrawerLeft/>
                </Col>

                <Col flex="auto" className="col-menu col-menu-drawer">
                    <TopMenu menu={newMenu}/>
                </Col>
                <Col className="col-menu col-menu-drawer">
                    <NotiGroup/>
                </Col>
            </Row>
        </Layout>
        {isShowAlert() ? <div className="alert-danger">
            <div className="container-fluid d-flex justify-content-center"
                 style={{alignSelf: 'center', alignItems: 'center'}}>
                <ExclamationCircleFilled/>
                <span className="pl-3">
                    Tài khoản của bạn chưa được kích hoạt. Vui lòng kiểm tra email hoặc
                    <span className="header__spn-border">
                    <a href="/verify-mail?token=">Click tại đây</a>
                    </span>

                </span>
            </div>
        </div> : ''}
    </>);
};

export default HeaderContent;
