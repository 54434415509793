export const ExamActionTypes = {
    API_RESPONSE_SUCCESS: '@@exam/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@exam/API_RESPONSE_ERROR',

    GET_EXAM: '@@exam/GET_EXAM',
    GET_INFO: '@@exam/GET_INFO',
    SUBMIT_EXAM: '@@exam/SUBMIT_EXAM',
    SAVE_EXAM: '@@exam/SAVE_EXAM',
    GET_HISTORY_EXAM: '@@exam/GET_HISTORY_EXAM',
};
