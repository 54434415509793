import React, { Fragment, useState } from 'react';
import { Drawer, Button } from 'antd';
import { Menu } from 'antd';
import './Drawer.scss';
import { Link } from 'react-router-dom';
import { BarsOutlined } from '@ant-design/icons';
import useMenu from '../../hooks/useMenu';

const { SubMenu } = Menu;
// submenu keys of first level

const DrawerLeft = () => {
    const menuCustom = useMenu();

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    return (
        <>
            <div className="mobileVisible ">
                <Button type="primary" className="menu-icon-primary" onClick={showDrawer}>
                    <BarsOutlined />
                </Button>
                <Drawer placement="right" onClose={onClose} visible={visible} className="ant-dbody " width={320}>
                    <Menu mode="inline" >
                        {menuCustom?.map((item) => (
                            <Fragment key={item.title}>
                                {item.link !== undefined ? (
                                    <Menu.Item key={item.title} icon={item.icon}>
                                        <Link onClick={onClose} to={item.link} style={{ textDecoration: 'none' }}>
                                            {item.title}
                                        </Link>
                                    </Menu.Item>
                                ) : (
                                    <>
                                        <SubMenu key={item.title} icon={item.icon} title={item.title}>
                                            {item.children.map((child) => (
                                                <Fragment key={child.title}>
                                                    {child.link !== undefined ? (
                                                        <Menu.Item icon={child.icon}>
                                                            <Link to={child.link} style={{ textDecoration: 'none' }}>
                                                                {child.title}
                                                            </Link>
                                                        </Menu.Item>
                                                    ) : (
                                                        <SubMenu icon={child.icon} title={child.title}>
                                                            {child.children.map((child2) => (
                                                                <Menu.Item key={child2.title}>
                                                                    <Link
                                                                        to={child.link}
                                                                        style={{ textDecoration: 'none' }}>
                                                                        {child2.title}
                                                                    </Link>
                                                                </Menu.Item>
                                                            ))}
                                                        </SubMenu>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </SubMenu>
                                    </>
                                )}
                            </Fragment>
                        ))}
                    </Menu>
                </Drawer>
            </div>
        </>
    );
};

export default DrawerLeft;
