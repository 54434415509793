import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Avatar, Dropdown, Menu, Space} from 'antd';
import {
    ClockCircleOutlined,
    HistoryOutlined,
    LogoutOutlined,
    ReadOutlined,
    UnorderedListOutlined,
    UserOutlined
} from '@ant-design/icons';
import {AiTwotoneStar} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux';
import {getDetailsUser, logoutUser} from '../../redux/actions';
import {notShow} from "../../utils/array";

const NotiGroup = () => {
    const dispatch = useDispatch();
    const {details, user} = useSelector((state) => state.Auth);

    const logOut = () => {
        dispatch(logoutUser());
    };

    useEffect(() => {
        if (user) dispatch(getDetailsUser());
    }, [user]);

    const userMenu = (
        <Menu>
            <Menu.Item key="1" icon={<UserOutlined/>}>
                Tài khoản
                <Link to="/dashboard/account"/>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="2" icon={<HistoryOutlined/>}>
                Lịch Sử Bài Thi
                <Link to="/dashboard/exam-history"/>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="3" icon={<ClockCircleOutlined/>}>
                Lịch Sử Điểm
                <Link to="/dashboard/point-history"/>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="4" icon={<ReadOutlined/>}>
                Khóa học gần đây
                <Link to="/dashboard/my-course-list"/>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="5" icon={<UnorderedListOutlined/>}>
                Danh sách bài tập
                <Link to="/dashboard/assignment-list"/>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="6" icon={<LogoutOutlined/>} onClick={() => logOut()}>
                Đăng xuất
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="noti-group">
            {user ? (
                <Dropdown overlay={userMenu} placement="bottomLeft">
                    <Space className="item" onClick={(e) => e.preventDefault()}>
                        <Avatar size={25} src={details?.user?.full_avatar_url}/>
                        <div className='d-flex align-items-center'>
                            <span className={'mr-4'}>{details?.user?.name} </span>
                            <span
                                className={`badge badge-pill  d-flex align-items-center ${details?.user?.point > 0 ? 'badge-success':'badge-danger'}`}> {details?.user?.point}
                                <AiTwotoneStar style={{fontSize: "14px"}}/></span>

                        </div>
                    </Space>
                </Dropdown>
            ) : (
                <>
                    <Link to="/login">
                        <button className="btn btn-danger px-4 rounded "
                                style={{paddingTop: '.2rem', paddingBottom: '.2rem'}}> Đăng nhập
                        </button>
                    </Link>
                    {(!notShow() ? '' : <Link to="/register">
                        <button className="btn btn-outline-info rounded  px-4 mx-3 rounded"
                                style={{paddingTop: '.2rem', paddingBottom: '.2rem'}}> Đăng ký
                        </button>
                    </Link>)}
                </>
            )}
        </div>
    );
};

export default NotiGroup;
