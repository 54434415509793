import React from 'react'
import styles from './Footer.module.scss';
import {Col, Row} from 'antd';
import {BsFacebook} from 'react-icons/bs'
import {AiFillYoutube} from 'react-icons/ai'
import {FaTiktok} from 'react-icons/fa'
import {MdChevronRight, MdOutlineEmail} from 'react-icons/md'
import {FiPhone} from 'react-icons/fi'
import {GoLocation} from 'react-icons/go'
import Logo from '../../assets/images/logo-Rikkei-footer.png'
import {MessageOutlined} from '@ant-design/icons';
import {useLocation,useRouteMatch } from "react-router-dom";

const Footer = () => {
    let location = useLocation()
    let match = useRouteMatch("/learn/:id");

    return (
        <>
            {location.pathname === "/feedback" || location.pathname === match?.url || location.pathname.split("/")[1] === "learn" ? "":
                <a href="/feedback" target="_blank" className={styles["feedback__icon"]} title="Đóng góp ý kiến"><MessageOutlined/></a>}

            <footer className={ `${styles['footer_wrapper']} ${location.pathname.split("/")[1] === "learn" && "d-none"}`}>

                <section className={styles['section_wrapper']}>
                    <Row gutter={24}>
                        <Col xs={24} lg={12} xl={6}>
                            <div className={styles['footer-column']}>
                                <a href='https://rikkei.edu.vn/' target={"_blank"}>
                                    <h2 className={styles['footer-heading']}>
                                        <img className={styles['footer_top-logo']}
                                             src={Logo}/>

                                        <span>Để nông dân biết code</span></h2>
                                </a>
                                <p className={styles['footer-contact-list']}>
                                    <FiPhone/> Điện thoại:
                                    <a href="tel:086 206 9233"> 024 36231 686</a>
                                    <br/>
                                    <MdOutlineEmail/> Email:
                                    <a href={"mailto:daotao@rikkeiacademy.com"}> daotao@rikkeiacademy.com</a>
                                    <br/>
                                    <GoLocation/> Địa chỉ: Tầng 7 khối A tòa nhà Sông Đà, đường Phạm Hùng , Phường Mỹ
                                    Đình
                                    1, Quận Nam Từ
                                    Liêm, Thành phố Hà Nội, Việt Nam
                                </p>
                                <div>
                                    <a href="/">
                                        <img
                                            src={"https://fullstack.edu.vn/static/media/dmca.2593d9ecf1c982e3c3a2.png"}/>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={0} lg={0} xl={6}>
                            <div className={styles['footer-column']}>
                                <div className={styles['footer-column']}>
                                    <h2 className={styles['footer-heading']}>Sản phẩm</h2>
                                    <ul className={styles['footer-list']}>
                                        <li><a href="https://rikkei.edu.vn/khoa-hoc-cho-nguoi-moi-bat-dau/"
                                               target={'_blank'}><MdChevronRight/> Dành cho người mới bắt đầu</a></li>
                                        <li><a href="https://rikkei.edu.vn/khoa-hoc-cho-nguoi-da-co-base-it/"
                                               target={'_blank'}><MdChevronRight/> Dành cho người có nền tảng</a></li>
                                        <li><a href="https://rikkei.edu.vn/khoa-road-to-japan/"
                                               target={'_blank'}><MdChevronRight/> Dành cho người muốn đi Nhật</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs={0} lg={0} xl={4}>
                            <div className={styles['footer-column']}>
                                <div className={styles['footer-column']}>
                                    <h2 className={styles['footer-heading']}>Hỗ trợ</h2>
                                    <ul className={styles['footer-list']}>
                                        <li><a href="/">Liên hệ</a></li>
                                        <li><a href="/">Bảo mật</a></li>
                                        <li><a href="/">Điều khoản</a></li>
                                        <li><a href="/">Cơ hội việc làm</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <div className={styles['footer-column']}>
                                <h2 className={styles['footer-heading']}>CÔNG TY TNHH RIKKEI EDUCATION</h2>
                                <ul className={styles['footer-list']}>
                                    <li>Mong muốn của chúng tôi sẽ mang đến thật nhiều cơ hội học tập
                                        và làm việc tại Nhật Bản cho các lập trình viên Việt Nam. Đến với
                                        Rikkei
                                        Academy bạn sẽ không còn cảm thấy lập trình viên là một nghề khó theo đuổi và
                                        cần
                                        trình độ cao, chỉ cần bạn quyết tâm và nỗ lực chỉ sau 6 tháng ắt bạn sẽ gặt trái
                                        ngọt.
                                    </li>
                                    <li className={'font-italic font-weight-bold'}>Anh Nguyễn Viết Lâm – CEO Rikkei
                                        Academy
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24} className={styles['footer-bottom']}>
                        <Col xs={24} lg={12} xl={12}>
                            <span className={styles['footer-bottom-text']}> © 2022 By Rikkei Academy - Rikkei Education - All rights reserved.</span>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                            <div className='d-flex justify-content-end'>
                                <a href={"https://www.facebook.com/rikkeiacademy"} target={"_blank"}><BsFacebook
                                    style={{
                                        color: "#3a589d",
                                        backgroundColor: "white",
                                        fontSize: "30px",
                                        margin: "4px",
                                        borderRadius: "50%",
                                        border: "1px solid #3a589d"
                                    }}/></a>
                                <a href={"https://www.youtube.com/c/RikkeiAcademy%C4%90%E1%BB%83n%C3%B4ngd%C3%A2nbi%E1%BA%BFtcode"}
                                   target={"_blank"}><AiFillYoutube
                                    style={{
                                        color: "white",
                                        backgroundColor: "#c33223",
                                        fontSize: "30px",
                                        margin: "4px",
                                        borderRadius: "50%",
                                        border: "1px solid #c33223"
                                    }}/> </a>
                                <a href={"https://www.tiktok.com/@rikkeiacademy?lang=vi-VN"} target={"_blank"}><FaTiktok
                                    style={{
                                        color: "white",
                                        backgroundColor: "#111",
                                        fontSize: "30px",
                                        margin: "4px",
                                        borderRadius: "50%",
                                        border: "1px solid #111"
                                    }}/></a>
                            </div>

                        </Col>
                    </Row>
                </section>
            </footer>
        </>
    )
}

export default Footer
