import { AssignmentTypes } from './constants';

const initialState = {
    assignmentDetail: {},
    assignmentUpdate: false,
    assignmentCreate: false,
    error: '',
};

export default function assignmentReducer(state = initialState, action) {
    switch (action.type) {
        case AssignmentTypes.RESPONSE_SUCCESS: {
            switch (action.payload.actionType) {
                case AssignmentTypes.GET_ASSIGNMENT_DETAIL:
                    return {
                        ...state,
                        assignmentDetail: action.payload.data?.data?.assignment,
                        loading: false,
                    };
                case AssignmentTypes.UPDATE_ASSIGNMENT_FILE:
                    return {
                        ...state,
                        assignmentUpdate: true,
                        loading: false,
                    };
                case AssignmentTypes.CREATE_ASSIGNMENT_FILE:
                    return {
                        ...state,
                        assignmentCreate: true,
                        loading: false,
                    };
                default:
                    return state;
            }
        }
        case AssignmentTypes.RESPONSE_ERROR: {
            switch (action.payload.actionType) {
                case AssignmentTypes.GET_ASSIGNMENT_DETAIL:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: false,
                    };
                case AssignmentTypes.UPDATE_ASSIGNMENT_FILE:
                    return {
                        ...state,
                        error: action.payload.error,
                        assignmentUpdate: false,
                        loading: true,
                    };
                case AssignmentTypes.CREATE_ASSIGNMENT_FILE:
                    return {
                        ...state,
                        error: action.payload.error,
                        assignmentCreate: false,
                        loading: true,
                    };
                default:
                    return state;
            }
        }
        case AssignmentTypes.GET_ASSIGNMENT_DETAIL:
            return {
                ...state,
                loading: true,
            };
        case AssignmentTypes.UPDATE_ASSIGNMENT_FILE:
            return {
                ...state,
                loading: true,
            };
        case AssignmentTypes.RESET:
            return {
                ...state,
                assignmentUpdate: false,
            };
        case AssignmentTypes.CREATE_ASSIGNMENT_FILE:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
