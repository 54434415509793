import {
    HomeOutlined,
    ClusterOutlined,
    ReadOutlined,
    InboxOutlined,
    AppstoreOutlined,
    MessageOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import { ROLES } from './lsm';

export const MENU = [
    {
        icon: <HomeOutlined />,
        title: 'Trang chủ',
        link: '/',
        roles: [ROLES.MEMBER, ROLES.CUSTOMER],
    },
    {
        icon: <InboxOutlined />,
        title: 'Lộ trình',
        link: '/roadmap-list',
        roles: [ROLES.MEMBER, ROLES.CUSTOMER],
    },
    {
        icon: <AppstoreOutlined />,
        title: 'Khóa học',
        link: '/course-list',
        roles: [ROLES.MEMBER, ROLES.CUSTOMER],
    },
    {
        icon: <ReadOutlined />,
        title: 'Bài viết',
        link: '/posts',
        roles: [ROLES.MEMBER, ROLES.CUSTOMER],
    },

    {
        icon: <ToolOutlined />,
        title: 'Công cụ',
        link: '/tools',
        roles: [ROLES.MEMBER, ROLES.CUSTOMER],
    },
    {
        icon: <ClusterOutlined />,
        title: 'Bảng điều khiển',
        link: '/dashboard',
        roles: [ROLES.MEMBER],
    },
    {
        icon: <MessageOutlined />,
        title: 'Đóng góp ý kiến',
        link: '/feedback',
        roles: [ROLES.MEMBER],
    },
];
