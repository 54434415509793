import { MaterialTypes } from './constants';

const initialState = {
  materialList: [],
  materialDetail: {},
  currentPage: 0,
  pageSize: 12,
  totalRecords: 0,
  loading: false,
  error: null,
  showError: false,
}

export default function materialReducer(state = initialState, action) {
  switch (action.type) {
    case MaterialTypes.RESPONSE_SUCCESS: {
      switch (action.payload.actionType) {
        case MaterialTypes.GET_MATERIAL_LIST:
          return {
            ...state,
            materialList: action.payload.data?.data?.materials?.data,
            currentPage: action.payload.data?.data?.materials?.current_page,
            pageSize: action.payload.data?.data?.materials?.to,
            totalRecords: action.payload.data?.data?.materials?.total,
            loading: false,
          };
        case MaterialTypes.GET_MATERIAL_DETAIL:
          return {
            ...state,
            materialDetail: action.payload.data?.data?.material,
            loading: false,
          };

        default:
          return state;
      }
    }
    case MaterialTypes.RESPONSE_ERROR: {
      switch (action.payload.actionType) {
        case MaterialTypes.GET_MATERIAL_LIST:
          return {
            ...state,
            error: action.payload.error,
            showError: true,
            loading: false,
          };

        case MaterialTypes.GET_MATERIAL_DETAIL:
          
          return {
            ...state,
            error: action.payload.error,
            showError: true,
            loading: false,
          };

        default:
          return state;
      }
    }
    case MaterialTypes.GET_MATERIAL_LIST:
      return {
        ...state,
        loading: true
      }
    case MaterialTypes.GET_MATERIAL_DETAIL:
      return {
        ...state,
        loading: true
      }
    case MaterialTypes.RESET_ERROR: {
      return {
        ...state,
        error: null,
        showError: false,
      };
    }
    default:
      return state
  }
}