// @flow
import { MENU } from '../constants/menu-custom';

import { useSelector } from 'react-redux';
import { ROLES } from '../constants/lsm'

const useMenu = () => {
    const {
        user,
    } = useSelector((state) => state.Auth);

    if (user) {
        return MENU;
    }
    
    return MENU.filter((item) => item?.roles.includes(ROLES.CUSTOMER));
};

export default useMenu;
