// @flow
import { APICore } from './apiCore';

const api = new APICore();

const baseUrl = 'api/student/modules';

function getModuleListApi(params) {
    return api.get(baseUrl, params);
}

export {
    getModuleListApi,
};
