// @flow
import { MaterialTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: MaterialTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: MaterialTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get material list
export const getMaterialList = (params) => {
    return {
        type: MaterialTypes.GET_MATERIAL_LIST,
        payload: { params },
    }
};

// action get material detail
export const getMaterialDetail = (params) => {
    return {
        type: MaterialTypes.GET_MATERIAL_DETAIL,
        payload: { params },
    }
};

//action reset error message
export const resetErrorMaterial = (params) => {
    return {
        type: MaterialTypes.RESET_ERROR,
        payload: { params },
    };
};
