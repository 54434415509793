import { RoadmapTypes } from './constants';

const initialState = {
    roadmapList: [],
    currentPage: 1,
    pageSize: 12,
    totalRecords: 0,
    loading: false,
    error: null,
    showError: false,
};

export default function roadmapReducer(state = initialState, action) {
    switch (action.type) {
        case RoadmapTypes.RESPONSE_SUCCESS: {
            switch (action.payload.actionType) {
                case RoadmapTypes.GET_ROADMAP_LIST:
                    return {
                        ...state,
                        roadmapList: action.payload.data?.data?.roadmaps?.data,
                        currentPage: action.payload.data?.data?.roadmaps?.current_page,
                        pageSize: action.payload.data?.data?.roadmaps?.to,
                        totalRecords: action.payload.data?.data?.roadmaps?.total,
                        loading: false,
                    };

                default:
                    return state;
            }
        }
        case RoadmapTypes.RESPONSE_ERROR: {
            switch (action.payload.actionType) {
                case RoadmapTypes.GET_ROADMAP_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: false,
                    };

                default:
                    return state;
            }
        }
        case RoadmapTypes.GET_ROADMAP_LIST:
            return {
                ...state,
                loading: true,
            };
        case RoadmapTypes.RESET_ERROR: {
            return {
                ...state,
                error: null,
                showError: false,
            };
        }
        default:
            return state;
    }
}
