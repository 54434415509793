// @flow
import { APICore } from './apiCore';

const api = new APICore();

// account

function getExam(id) {
    const baseUrl = `api/student/take-exam/${id}`;
    return api.get(`${baseUrl}`);
}

function getHistoryExam(params) {
    const baseUrl = `/api/student/get-history-exam`;
    return api.create(`${baseUrl}`, params);
}

function getInfo() {
    const baseUrl = `api/student/me`;
    return api.get(`${baseUrl}`);
}

function submitExam(params) {
    const baseUrl = `api/student/submit-exam`;
    return api.create(`${baseUrl}`, params);
}

export { getExam, getInfo, submitExam, getHistoryExam };
