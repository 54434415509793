// @flow
import { AttendanceActionTypes } from './constants';

const INIT_STATE = {
    loading: false,

    list_reason: [],

    error: null,
};

type ReasonAction = { type: string, payload: { actionType?: string, data?: any, error?: string } };
type State = { reason?: {} | null, loading?: boolean };

const Attendance = (state: State = INIT_STATE, action: ReasonAction): any => {
    switch (action.type) {
        case AttendanceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AttendanceActionTypes.GET_REASON: {
                    return {
                        ...state,
                        list_reason: action.payload.data,
                        loading: false,
                    };
                }
                case AttendanceActionTypes.DELETE_REASON: {
                    return {
                        ...state,
                        list_reason: state.list_reason.filter((i) => i.id !== action.payload.data),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }
        case AttendanceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AttendanceActionTypes.GET_REASON: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case AttendanceActionTypes.DELETE_REASON: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }
        case AttendanceActionTypes.GET_EXAM:
            return {
                ...state,
                loading: true,
            };

            return {
                ...state,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default Attendance;
