// @flow
import React, {Suspense, useEffect, useState} from 'react';
// actions
import {Content, Header} from 'antd/lib/layout/layout';
import HeaderContent from './header-content/HeaderContent';
import Footer from './footer/Footer';
import {Button, Layout, Modal, notification} from 'antd';
import useMenu from '../hooks/useMenu';
import Lottie from 'react-lottie';
import loadingJson from '../assets/animation/loading.json';
import Clock from "react-clock";
import 'react-clock/dist/Clock.css';
import {getHistoryAttendance, isCheckinUser, submitAttendanceForm} from "../helpers/api/myAttendanceApi";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {notShow} from "../utils/array";

type VerticalLayoutProps = {
    children?: any,
};

type VerticalLayoutState = {
    isMenuOpened?: boolean,
};
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const KeyLocalStorage = 'modalSetting'
const DetachedLayout = ({children}: VerticalLayoutProps, state: VerticalLayoutState): React$Element<any> => {
    const dispatch = useDispatch();
    const {details, user} = useSelector((state) => state.Auth);
    const [modalRollCall, setModalRollCall] = useState(false);
    const [monthCurrent] = useState(moment().format('Y-M'));
    const [today] = useState(moment().format('Y-M-DD'));
    const [now] = useState(moment().format('Y-M-DD HH:mm:ss'));
    const [isCheckin, setIsCheckin] = useState(false);
    const [index, setIndex] = useState(1);
    const [isLoadBtn, setIsLoadBtn] = useState(false);
    const [value, setValue] = useState(new Date());
    const loading = () => (
        <div
            style={{
                display: 'flex',
                width: '100%',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            className="text-center">
            <Lottie height={200} width={200} options={defaultOptions}/>
        </div>
    );
    // useEffect(() => {
    //     const interval = setInterval(() => setValue(new Date()), 1000);
    //     if (index === 1) {
    //         if(!notShow()) return ;
    //         checkStatusUser()
    //     }
    //     setIndex(index + 1)
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [value]);

    // useEffect(()=>{
    //     const isHideOpenModalRollCall = ()=>{
    //         if(!notShow()) return false;
    //         return  true;
    //     }
    //     setModalRollCall(isHideOpenModalRollCall())
    // },[])
    // useEffect(() => {
    //     const interval = setInterval(() => setValue(new Date()), 1000);
    //     if (index === 1) {
    //         if(!notShow()) return ;
    //         checkStatusUser()
    //     }
    //     setIndex(index + 1)
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [value]);
    //
    // useEffect(()=>{
    //     const isHideOpenModalRollCall = ()=>{
    //         if(!notShow()) return false;
    //         return  true;
    //     }
    //     setModalRollCall(isHideOpenModalRollCall())
    // },[])

    const checkStatusUser = async () => {
        let startTimeAtBatch =  moment('07:15:00', 'H:mm:ss').format('H:mm:ss')
        let endTimeAtBatch =  moment('17:15:00', 'H:mm:ss').format('H:mm:ss')
        if (!user) return false
        try {
            const result = await isCheckinUser();
            // setIsCheckin(result.data.data.checkedIn);
            // setModalRollCall(!result.data.data.checkedIn);
            // let rollCallToday = await getHistoryRollCall()
            // let modalSetting = window.localStorage.getItem(KeyLocalStorage)
            // if(modalSetting){
            //     modalSetting = JSON.parse(modalSetting);
            //     if(modalSetting.type === 'checkin' && today === modalSetting.date) return setModalRollCall(false)
            //     if(modalSetting.type === 'checkout' && today === modalSetting.date) return setModalRollCall(false)
            // }
            // rollCallToday = rollCallToday.find(el => el.day === today)
            // let hadCheckIn = () => {
            //     if (rollCallToday?.length <= 0) return false
            //     if (rollCallToday?.roll_call_report.length <= 0) return false
            //     let timeCheckin = rollCallToday.roll_call_report.find(el => el.time_check_in)
            //     if (!timeCheckin && now >= startTimeAtBatch) {
            //         setIsCheckin(false);
            //         return false
            //     }
            //     return true
            // }
            // let hadCheckout = () => {
            //     if (rollCallToday?.length <= 0) return false
            //     if (rollCallToday?.roll_call_report.length <= 0) return false
            //     let timeCheckin = rollCallToday.roll_call_report.find(el => el.time_check_in)
            //     let timeCheckout = rollCallToday.roll_call_report.find(el => el.time_check_out)?.time_check_out
            //     if (!timeCheckin) {
            //         setIsCheckin(false);
            //         return false
            //     }
            //
            //     if (now < endTimeAtBatch) return true
            //
            //     if (timeCheckout && timeCheckout < endTimeAtBatch) {
            //         setModalRollCall(false)
            //         return false
            //     }
            //     if (!timeCheckout && now < endTimeAtBatch) {
            //         return true
            //     }
            //     return true
            // }
            // if (!hadCheckIn()) {
            //     setModalRollCall(true);
            // }
            // if (!hadCheckout()) {
            //     setModalRollCall(true);
            // }
        } catch (error) {
            console.log(error)
            notification.error({message: error});
        }
    };

    async function handleClick() {
        setIsLoadBtn(true)
        try {
            const data = await submitAttendanceForm();
            await checkStatusUser();
            await setIsLoadBtn(false)
            await notification.success({message: data.data.message});
        } catch (error) {
            setIsLoadBtn(false)
            notification.error({message: error});
        }
    }

    async function getHistoryRollCall() {
        try {
            const res = await getHistoryAttendance({date: monthCurrent})
            return res.data.data.rollCallReports
        } catch (error) {
            console.log(error)
        }
    }

    function closeModalRollCall(type) {
        window.localStorage.setItem('modalSetting', JSON.stringify({type: type, date: today}))
        setModalRollCall(false)
    }

    const menuCustom = useMenu();
    return (
        <>
            <Layout>
                <Header>
                    <HeaderContent menu={menuCustom}/>
                </Header>
                <Content>
                    <Suspense fallback={loading()}>{children}</Suspense>
                </Content>
                <Footer/>
            </Layout>
            {/*<Modal*/}
            {/*    centered*/}
            {/*    visible={modalRollCall}*/}
            {/*    onCancel={() => {*/}
            {/*        isCheckin? closeModalRollCall('checkout'): closeModalRollCall('checkin')*/}
            {/*    }}*/}
            {/*    footer={[<div>*/}
            {/*        <Button key="submit" type="primary" onClick={handleClick}*/}
            {/*                loading={isLoadBtn}>{!isCheckin ? 'Điểm danh đến' : 'Điểm danh về'}</Button>*/}
            {/*        <Button key="submit" type="default" onClick={() => {*/}
            {/*           isCheckin? closeModalRollCall('checkout'): closeModalRollCall('checkin')*/}
            {/*        }}>*/}
            {/*            Đóng*/}
            {/*        </Button>*/}

            {/*    </div>]}*/}
            {/*>*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-6 offset-4">*/}
            {/*            <Clock value={value}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </>
    );
};

export default DetachedLayout;
