// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AssignmentTypes } from './constants';
import { responseSuccess, responseError } from './actions';
import { getAssignmentDetailApi, updateFileAssignments, createFileAssignments } from '../../helpers/api/assignmentApi';

function* getAssignmentDetailSaga({ payload: { params } }) {
    try {
        const response = yield call(getAssignmentDetailApi, params);
        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(AssignmentTypes.GET_ASSIGNMENT_DETAIL, data));
        }
    } catch (error) {
        yield put(responseError(AssignmentTypes.GET_ASSIGNMENT_DETAIL, error));
    }
}

function* updateAssignment({ payload: { id, data } }) {
    try {
        const res = yield call(updateFileAssignments, { id, data });
        if (res?.status === 200) {
            yield put(responseSuccess(AssignmentTypes.UPDATE_ASSIGNMENT_FILE, {}));
        } else {
            yield put(responseError(AssignmentTypes.UPDATE_ASSIGNMENT_FILE, res?.message));
        }
    } catch (error) {
        yield put(responseError(AssignmentTypes.UPDATE_ASSIGNMENT_FILE, error));
    }
}

function* createAssignments({ payload: { params } }) {
    try {
        const res = yield call(createFileAssignments, params);
        const data = res?.data;
        if (res?.status === 200) {
            yield put(responseSuccess(AssignmentTypes.CREATE_ASSIGNMENT_FILE, data));
        } else {
            yield put(responseError(AssignmentTypes.CREATE_ASSIGNMENT_FILE, res?.message));
        }
    } catch (error) {
        yield put(responseError(AssignmentTypes.CREATE_ASSIGNMENT_FILE, error));
    }
}

function* assignmentSaga() {
    yield all([
        takeLatest(AssignmentTypes.GET_ASSIGNMENT_DETAIL, getAssignmentDetailSaga),
        takeLatest(AssignmentTypes.UPDATE_ASSIGNMENT_FILE, updateAssignment),
        takeLatest(AssignmentTypes.CREATE_ASSIGNMENT_FILE, createAssignments),
    ]);
}
export default assignmentSaga;
