// @flow
import { all, fork, put, call } from 'redux-saga/effects';

import {
    getExam as apiGetExam,
    getInfo as apiGetInfo,
    submitExam as apiSubmitExam,
    getHistoryExam as apigetHistoryExam,
} from '../../helpers/api/examApi';
import { apiResponseSuccess, apiResponseError, saveExam } from './actions';
import { ExamActionTypes } from './constants';
import { takeLatest } from 'redux-saga/effects';

function* getExam({ payload: { id } }) {
    try {
        const response = yield call(apiGetExam, id);
        const { data } = response.data;
        yield put(apiResponseSuccess(ExamActionTypes.GET_EXAM, data?.exam));
    } catch (error) {
        yield put(apiResponseError(ExamActionTypes.GET_EXAM, error));
    }
}

function* getInfo() {
    try {
        const response = yield call(apiGetInfo);
        const { data } = response.data;
        yield put(apiResponseSuccess(ExamActionTypes.GET_INFO, data?.user));
    } catch (error) {
        yield put(apiResponseError(ExamActionTypes.GET_INFO, error));
    }
}

function* submitExam({ payload: { params } }) {
    try {
        const response = yield call(apiSubmitExam, params);
        const data = response.data;
        yield put(apiResponseSuccess(ExamActionTypes.SUBMIT_EXAM, data));
        yield put(saveExam(params.data));
    } catch (error) {
        yield put(apiResponseError(ExamActionTypes.SUBMIT_EXAM, error));
    }
}

function* getHistoryExam({ payload: { params } }) {
    try {
        const response = yield call(apigetHistoryExam, params);
        const { data } = response.data;
        yield put(apiResponseSuccess(ExamActionTypes.GET_HISTORY_EXAM, data));
    } catch (error) {
        yield put(apiResponseError(ExamActionTypes.GET_HISTORY_EXAM, error));
    }
}

export function* watchGetExam(): any {
    yield takeLatest(ExamActionTypes.GET_EXAM, getExam);
}

export function* watchGetInfo(): any {
    yield takeLatest(ExamActionTypes.GET_INFO, getInfo);
}

export function* watchSubmitExam(): any {
    yield takeLatest(ExamActionTypes.SUBMIT_EXAM, submitExam);
}

export function* watchGetHistoryExam(): any {
    yield takeLatest(ExamActionTypes.GET_HISTORY_EXAM, getHistoryExam);
}

function* examSaga(): any {
    yield all([fork(watchGetExam), fork(watchGetInfo), fork(watchSubmitExam), fork(watchGetHistoryExam)]);
}

export default examSaga;
