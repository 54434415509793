// @flow
import { ExamActionTypes } from './constants';

const INIT_STATE = {
    loading: false,
    info: null,
    exam: {
        exam_question: [],
    },
    submitExamSuccess: false,
    studentGrade: {},
    resultExam: {},
    historyExam: {},
    error: null,
};

type ExamAction = { type: string, payload: { actionType?: string, data?: any, error?: string } };
type State = { exam?: {} | null, loading?: boolean };

const Auth = (state: State = INIT_STATE, action: ExamAction): any => {
    switch (action.type) {
        case ExamActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ExamActionTypes.GET_EXAM: {
                    return {
                        ...state,
                        exam: action.payload.data,
                        loading: false,
                    };
                }
                case ExamActionTypes.GET_INFO: {
                    return {
                        ...state,
                        info: action.payload.data,
                        loading: false,
                    };
                }
                case ExamActionTypes.SUBMIT_EXAM: {
                    
                    return {
                        ...state,
                        studentGrade: action.payload.data?.data?.studentGrade,
                        resultExam: action.payload.data?.data?.exam,
                        submitExamSuccess: true,
                        loading: false,
                    };
                }
                case ExamActionTypes.GET_HISTORY_EXAM: {
                    return {
                        ...state,
                        historyExam: action.payload.data.listHistoryExam,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case ExamActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ExamActionTypes.GET_EXAM: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case ExamActionTypes.GET_INFO: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case ExamActionTypes.SUBMIT_EXAM: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case ExamActionTypes.GET_HISTORY_EXAM: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case ExamActionTypes.GET_EXAM:
            return {
                ...state,
                loading: true,
            };
        case ExamActionTypes.GET_INFO:
            return {
                ...state,
                loading: true,
            };
        case ExamActionTypes.SUBMIT_EXAM:
            return {
                ...state,
                submitExamSuccess: false,
                loading: true,
            };

        case ExamActionTypes.SAVE_EXAM:
            return {
                ...state,
                answerExam: action?.payload?.params,
                loading: false,
            };
        case ExamActionTypes.GET_HISTORY_EXAM:
            return {
                ...state,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default Auth;
