import React, { Fragment } from 'react';
import './TopMenu.scss';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

const { SubMenu } = Menu;

const TopMenu = ({ menu }) => {
    const location = useLocation();
    return (
        <Menu mode="horizontal" selectedKeys={[location?.pathname]}>
            {menu?.map((item, index) => (
                <Fragment key={index}>
                    {item.link !== undefined ? (
                        <Menu.Item key={item.link} icon={item.icon}>
                            <Link to={item.link} style={{ textDecoration: 'none' }}>
                                {item.title}
                            </Link>
                        </Menu.Item>
                    ) : (
                        <>
                            <SubMenu key={item.link} icon={item.icon} title={item.title}>
                                {item.children.map((child, i) => (
                                    <Fragment key={i}>
                                        {child.link !== undefined ? (
                                            <Menu.Item key={child.link} icon={child.icon}>
                                                <Link to={child.link} style={{ textDecoration: 'none' }}>
                                                    {child.title}
                                                </Link>
                                            </Menu.Item>
                                        ) : (
                                            <SubMenu key={child.link} icon={child.icon} title={child.title}>
                                                {child.children.map((child2) => (
                                                    <Menu.Item key={child2.link}>
                                                        <Link to={child.link} style={{ textDecoration: 'none' }}>
                                                            {child2.title}
                                                        </Link>
                                                    </Menu.Item>
                                                ))}
                                            </SubMenu>
                                        )}
                                    </Fragment>
                                ))}
                            </SubMenu>
                        </>
                    )}
                </Fragment>
            ))}
        </Menu>
    );
};

export default TopMenu;
