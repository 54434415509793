// @flow
import { all, fork, put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { AttendanceActionTypes } from './constants';
import { getReason as apiGetReason, deleteReason as apiDeleteReason } from '../../helpers/api/myAttendanceApi';
import { apiResponseSuccess, apiResponseError } from './actions';

function* getReason({ payload: { params } }) {
    try {
        const response = yield call(apiGetReason, params);
        const { data } = response.data.data.offLearnReasons;

        yield put(apiResponseSuccess(AttendanceActionTypes.GET_REASON, data));
    } catch (error) {
        yield put(apiResponseError(AttendanceActionTypes.GET_REASON, error));
    }
}

function* deleteReason({ payload: { params } }) {
    try {
        const response = yield call(apiDeleteReason, params);
        yield put(apiResponseSuccess(AttendanceActionTypes.DELETE_REASON, params));
    } catch (error) {
        yield put(apiResponseError(AttendanceActionTypes.DELETE_REASON, error));
    }
}

export function* watchGetReason(): any {
    yield takeLatest(AttendanceActionTypes.GET_REASON, getReason);
}

export function* watchDeleteReason(): any {
    yield takeLatest(AttendanceActionTypes.DELETE_REASON, deleteReason);
}

function* attendanceSaga(): any {
    yield all([fork(watchGetReason), fork(watchDeleteReason)]);
}

export default attendanceSaga;
