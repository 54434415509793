// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CourseTypes } from './constants';
import {
    responseSuccess,
    responseError,
    setModalRatting,
    getListTopic,
} from './actions';
import {
    getCourseListApi,
    getCourseDetailApi,
    updateCourse,
    listExam as apiListExam,
    listTopic as apiListTopic,
    topicDetail as apiTopicDetail,
    createTopic as apiCreateTopic,
    deleteTopic as apiDeleteTopic,
    updateTopic as apiUpdateTopic,
    createCommentStudent as apiCreateCommentStudent,
    deleteCommentStudent as apiDeleteCommentStudent,
    updateCommentStudent as apiUpdateCommentStudent,
    checkPermissions as apicheckPermissions,
    createRatting as apiRatting,
    updateRatting as apiUpdateRatting,
    previewCourseForAdmin
} from '../../helpers/api/courseApi';
import {notification} from 'antd';

function* getCourseListSaga({payload: {params}}) {
    try {
        const response = yield call(getCourseListApi, params);

        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(CourseTypes.GET_COURSE_LIST, data));
        }
    } catch (error) {
        yield put(responseError(CourseTypes.GET_COURSE_LIST, error));
    }
}

function* getCourseDetailSaga({payload: {params}}) {
    try {
        const response = yield call(getCourseDetailApi, params);

        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(CourseTypes.GET_COURSE_DETAIL, data));
        }
    } catch (error) {
        yield put(responseError(CourseTypes.GET_COURSE_DETAIL, error));
    }
}

function* listExam({payload: {params}}) {
    try {
        const response = yield call(apiListExam, params);
        const data = response.data;
        yield put(responseSuccess(CourseTypes.GET_LIST_EXAM, data?.data));
    } catch (error) {
        yield put(responseError(CourseTypes.GET_LIST_EXAM, error));
    }
}

function* listTopic({payload: {params}}) {
    try {
        const response = yield call(apiListTopic, params);
        let data = response.data;
        data.data.isAddNew = false
        data.data.fileId = params.file_id
        if (params.isAddNew || params.isUpdate || params.isDelete) {
            data.data.isAddNew = true
        }
        yield put(responseSuccess(CourseTypes.GET_LIST_TOPIC, data?.data));
    } catch (error) {
        yield put(responseError(CourseTypes.GET_LIST_TOPIC, error));
    }
}

function* topicDetail({payload: {params}}) {
    try {
        const response = yield call(apiTopicDetail, {topic_id: params});
        const data = response.data;
        yield put(responseSuccess(CourseTypes.GET_TOPIC_DETAIL, data?.data));
    } catch (error) {
        yield put(responseError(CourseTypes.GET_TOPIC_DETAIL, error));
    }
}

function* createTopic({payload: {params}}) {
    try {
        let {
            topicForm, file_id, isAddNew, page, per_page
        } = params
        const response = yield call(apiCreateTopic, topicForm);
        const data = response.data;
        if (response?.status === 200) {
            notification.success({message: data.message});
            yield put(responseSuccess(CourseTypes.CREATE_TOPIC, data));
            yield put(getListTopic({file_id, isAddNew, page, per_page}));
        } else {
            yield put(responseSuccess(CourseTypes.CREATE_TOPIC, response?.message));
        }
    } catch (error) {
        yield put(responseError(CourseTypes.CREATE_TOPIC, error));
    }
}

function* deleteTopic({payload: {params}}) {
    try {
        const response = yield call(apiDeleteTopic, params);
        let data = response.data;
        data = {status: data.status, message: data.message, ...params}
        notification.success({message: data.message});
        yield put(responseSuccess(CourseTypes.DELETE_TOPIC, data));
        yield put(getListTopic({
            file_id:params.file_id,
            course_id: params.course_id,
            isDelete: true,
            page: params.page,
            per_page: params.per_page
        }));
    } catch (error) {
        yield put(responseError(CourseTypes.DELETE_TOPIC, error));
        notification.error({message: 'xóa thất bại'});
    }
}

function* updateTopic({ payload: params }) {
    try {
        const response = yield call(apiUpdateTopic, params);
        const data = response.data;
        notification.success({ message: data.message });
        yield put(responseSuccess(CourseTypes.UPDATE_TOPIC, { data, params }));
    } catch (error) {
        yield put(responseError(CourseTypes.UPDATE_TOPIC, error));
        notification.error({ message: 'Cập nhật thất bại' });
    }
}

function* createCommentStudent({ payload: params }) {
    try {
        const response = yield call(apiCreateCommentStudent, params);
        const data = response.data;
        notification.success({ message: data.message });
        yield put(responseSuccess(CourseTypes.CREATE_COMMENT_STUDENT, data));
    } catch (error) {
        yield put(responseError(CourseTypes.CREATE_COMMENT_STUDENT, error));
        notification.error({ message: 'Trả lời thất bại' });
    }
}

function* deleteCommentStudent({ payload: params }) {
    try {
        const response = yield call(apiDeleteCommentStudent, params);
        const data = response.data;
        notification.success({ message: data.message });
        yield put(responseSuccess(CourseTypes.DELETE_COMMENT_STUDENT, data));
    } catch (error) {
        yield put(responseError(CourseTypes.DELETE_COMMENT_STUDENT, error));
        notification.error({ message: 'Xóa thất bại' });
    }
}

function* updateCommentStudent({ payload: params }) {
    try {
        const response = yield call(apiUpdateCommentStudent, params);
        const data = response.data;
        notification.success({ message: data.message });
        yield put(responseSuccess(CourseTypes.UPDATE_COMMENT_STUDENT, data));
    } catch (error) {
        yield put(responseError(CourseTypes.UPDATE_COMMENT_STUDENT, error));
        notification.error({ message: 'Cập nhật thất bại' });
    }
}

function* checkPermissions({ payload: { params } }) {
    try {
        const response = yield call(apicheckPermissions, params);
        const data = response.data;
        yield put(responseSuccess(CourseTypes.CHECK_PERMISSIONS, data));
    } catch (error) {
        yield put(responseError(CourseTypes.CHECK_PERMISSIONS, error));
    }
}

function* updateCourses({ payload: { data } }) {
    try {
        const res = yield call(updateCourse, data);
        if (res?.status === 200) {
            yield put(responseSuccess(CourseTypes.UPDATE_COURSE, {}));
            if (data.file_url || data.link_source_code) {
                return notification.success({ message: 'Nộp bài thành công.' });
            }
            return notification.success({ message: 'Bạn đã hoàn thành bài học.' });
        } else {
            yield put(responseSuccess(CourseTypes.UPDATE_COURSE, res?.message));
            return notification.error({ message: res?.message });
        }
    } catch (error) {
        yield put(responseSuccess(CourseTypes.UPDATE_COURSE, error));
    }
}

function* updateModalRatting({ payload: { data } }) {
    yield setModalRatting(CourseTypes.SET_MODAL_RATTING, data);
}

function* updateRatting({ payload }) {
    try {
        const response = yield call(apiUpdateRatting, payload);
        const res = response.data;
        if (response?.status === 200) {
            yield put(responseSuccess(CourseTypes.UPDATE_RATTING, res));
            notification.success({ message: 'Đánh giá thành công' });
            // yield put(setModalRatting(false))
        } else {
            yield put(responseSuccess(CourseTypes.UPDATE_RATTING, response?.message));
        }
    } catch (error) {
        yield put(responseError(CourseTypes.UPDATE_RATTING, error));
    }
}

function* createRatting({ payload }) {
    try {
        const response = yield call(apiRatting, payload);
        const res = response.data;
        if (response?.status === 200) {
            yield put(responseSuccess(CourseTypes.FORM_RATTING, res));
            notification.success({ message: 'Đánh giá thành công' });
            // yield put(setModalRatting(false))
        } else {
            yield put(responseSuccess(CourseTypes.FORM_RATTING, response?.message));
        }
    } catch (error) {
        yield put(responseError(CourseTypes.FORM_RATTING, error));
    }
}

function* courseForAdmin({ payload }) {
    try {
        const response = yield call(previewCourseForAdmin, payload);
        const res = response.data;
        if (response?.status === 200) {
            yield put(responseSuccess(CourseTypes.COURSE_FOR_ADMIN, res));
        } else {
            yield put(responseSuccess(CourseTypes.COURSE_FOR_ADMIN, response?.message));
        }
    } catch (error) {
        yield put(responseError(CourseTypes.COURSE_FOR_ADMIN, error));
    }
}

function* courseSaga() {
    yield all([
        takeLatest(CourseTypes.GET_COURSE_LIST, getCourseListSaga),
        takeLatest(CourseTypes.GET_COURSE_DETAIL, getCourseDetailSaga),
        takeLatest(CourseTypes.GET_LIST_EXAM, listExam),
        takeLatest(CourseTypes.GET_LIST_TOPIC, listTopic),
        takeLatest(CourseTypes.GET_TOPIC_DETAIL, topicDetail),
        takeLatest(CourseTypes.CREATE_TOPIC, createTopic),
        takeLatest(CourseTypes.DELETE_TOPIC, deleteTopic),
        takeLatest(CourseTypes.UPDATE_TOPIC, updateTopic),
        takeLatest(CourseTypes.CREATE_COMMENT_STUDENT, createCommentStudent),
        takeLatest(CourseTypes.DELETE_COMMENT_STUDENT, deleteCommentStudent),
        takeLatest(CourseTypes.UPDATE_COMMENT_STUDENT, updateCommentStudent),
        takeLatest(CourseTypes.CHECK_PERMISSIONS, checkPermissions),
        takeLatest(CourseTypes.UPDATE_COURSE, updateCourses),
        takeLatest(CourseTypes.SET_MODAL_RATTING, updateModalRatting),
        takeLatest(CourseTypes.FORM_RATTING, createRatting),
        takeLatest(CourseTypes.UPDATE_RATTING, updateRatting),
        takeLatest(CourseTypes.COURSE_FOR_ADMIN, courseForAdmin),
    ]);
}

export default courseSaga;
