// @flow
import { ExamActionTypes } from './constants';

type ExamAction = { type: string, payload: {} | string };

// common success
export const apiResponseSuccess = (actionType: string, data: any): ExamAction => ({
    type: ExamActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const apiResponseError = (actionType: string, error: string): ExamAction => ({
    type: ExamActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getExam = (id: string): ExamAction => ({
    type: ExamActionTypes.GET_EXAM,
    payload: { id },
});

export const getInfo = (): ExamAction => ({
    type: ExamActionTypes.GET_INFO,
});

export const submitExam = (params: any): ExamAction => ({
    type: ExamActionTypes.SUBMIT_EXAM,
    payload: { params },
});

export const saveExam = (params: any): ExamAction => ({
    type: ExamActionTypes.SAVE_EXAM,
    payload: { params },
});

export const getHistoryExam = (params): ExamAction => ({
    type: ExamActionTypes.GET_HISTORY_EXAM,
    payload: { params },
});
