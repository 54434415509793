// @flow
import { DashboardTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: DashboardTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: DashboardTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get dashboard data
export const getDashboardData = (params) => {
    return {
        type: DashboardTypes.GET_DASHBOARD_DATA,
        payload: { params },
    };
};

//action reset error message
export const resetErrorDashboard = (params) => {
    return {
        type: DashboardTypes.RESET_ERROR,
        payload: { params },
    };
};

export const getMyCourseList = (params) => {
    return {
        type: DashboardTypes.GET_MY_COURSE_LIST,
        payload: { params },
    };
};

export const getMyAssignment = (params) => {
    return {
        type: DashboardTypes.GET_MY_ASSIGNMENTS,
        payload: { params },
    };
};
