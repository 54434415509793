import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const {
        user,
    } = useSelector((state) => state.Auth);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (user) return <Component {...props} />

                return <Redirect to={{ pathname: '/login', state: { ...props?.location?.state, nextPath: props.location?.pathname } }} />;
            }}
        />
    );
};

export default PrivateRoute;
