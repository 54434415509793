// @flow
import { APICore, setAuthorization } from './apiCore';

const api = new APICore();

// account

function loginGoogle(params) {
    // const body = { firebaseToken: params?.firebaseToken };
    setAuthorization(params?.accessToken);
    // return api.create('auth/token', body);
    return api.create('auth/token');
}

function login(params) {
    return api.create('api/student/login', params);
}

function verifyEmail(params) {
    return api.get('api/student/active-user?token=' + params.token);
}

function changePassword(params) {
    return api.create('api/student/change-password', params);
}

function resendMail(params) {
    return api.create('api/student/resend-email-active-account', params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params) {
    const baseUrl = 'api/student/register';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params) {
    const baseUrl = '/api/student/send-mail-reset-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params) {
    const baseUrl = '/api/student/reset-password';
    return api.create(`${baseUrl}`, params);
}

function getDetail() {
    const baseUrl = 'api/student/me/';
    return api.get(`${baseUrl}`);
}

function updateUser(params) {
    const baseUrl = 'api/student/edit-profile/';
    return api.create(`${baseUrl}`, params);
}

function getAllProjects() {
    const baseUrl = 'api/project/';
    return api.get(`${baseUrl}`);
}

function updateToken(params) {
    const baseUrl = 'token';
    return api.create(`${baseUrl}`, params);
}

function uploadFile(params) {
    const baseUrl = 'api/upload-file/';
    return api.createWithFile(`${baseUrl}`, params);
}

function getInfoCurrentUser() {
    const baseUrl = 'api/student/current-student-info';
    return api.get(`${baseUrl}`);
}

function updateInfoCurrentUser(params) {
    const baseUrl = 'api/student/update-current-student-info';
    return api.create(`${baseUrl}`, params);
}

function createInfoCurrentUser(params) {
    const baseUrl = 'api/student/basic-student-info';
    return api.create(`${baseUrl}`, params);
}
function batchInfoCurrentUser(params) {
    const baseUrl = 'api/student/get-batch-of-current-user';
    return api.get(`${baseUrl}`, params);
}

export {
    loginGoogle,
    login,
    logout,
    signup,
    forgotPassword,
    forgotPasswordConfirm,
    getDetail,
    updateUser,
    getAllProjects,
    updateToken,
    uploadFile,
    verifyEmail,
    resendMail,
    changePassword,
    getInfoCurrentUser,
    updateInfoCurrentUser,
    createInfoCurrentUser,
};
