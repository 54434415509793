export const ROLES = {
    CUSTOMER: 0,
    MEMBER: 1,
};

const LEVEL = {
    BEGINNER: 1,
    INTERMEDIATE: 2,
    ADVANCED: 3,
}

const MATERIAL_TYPE = {
    VIDEO: 0,
    DOC: 1,
}

export const LEVEL_SUMMARY = [
    [LEVEL.BEGINNER, 'Beginner'],
    [LEVEL.INTERMEDIATE, 'Intermediate'],
    [LEVEL.ADVANCED, 'Advanced'],
];

export const MATERIAL_TYPE_SUMMARY = [
    [MATERIAL_TYPE.VIDEO, 'Video'],
    [MATERIAL_TYPE.DOC, 'Doc'],
];
export const RATTING = [
    {val: 1, label: 'Chưa hiểu'},
    {val: 2, label: 'Chưa hiểu rõ'},
    {val: 3, label: 'Đã hiểu'},
];

export const ATTENDANCE_DAY_TYPE = [
    {val: 1, label: "Bổ sung điểm danh"},
    {val: 2, label: "Xin nghỉ phép"},
]

export const ACCEPTED_STATUS_TYPE = [
    {val: 0, label: "Đang chờ", color: ""},
    {val: 1, label: "Chấp nhận", color: "green"},
    {val: 2, label: "Từ chối", color: "red"}
]


export const TYPE_TIME_DAY_OFF = {
    START_SESSION: "08:00",
    START_AFTERNOON_SESSION: "13:30",
    END_SESSION: "17:30",
    END_MORNING_SESSION: "12:00",
}

export const TYPE_OF_REASON = [
    {value:1,label:"Nghỉ cả ngày"},
    {value:2,label:"Nghỉ buổi sáng"},
    {value:3,label:"Nghỉ buổi chiều"},
    {value:4,label:"Đến muộn"},
    {value:5,label:"Về sớm"},
]

export const TYPE_OF_REASON_ADD = [
    {value:6, label:"Quên check-in hoặc check-out"}
]