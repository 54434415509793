// @flow
import { HomeTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: HomeTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: HomeTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get home data
export const getHomeData = (params) => {
    return {
        type: HomeTypes.GET_HOME_DATA,
        payload: { params },
    };
};

//action reset error message
export const resetErrorHome = (params) => {
    return {
        type: HomeTypes.RESET_ERROR,
        payload: { params },
    };
};

export const getSearchHome = (params) => {
    return {
        type: HomeTypes.GET_SEARCH_HOME_DATA,
        payload: { params },
    };
};
