// @flow
import { APICore, setAuthorization } from './apiCore';
// import axios from 'axios';

const api = new APICore();

const baseUrl = 'api/student/list-roadmap-user';

function getDashboardDataApi(params) {
    return api.get(baseUrl, params);
}

function getMyAssignmentsApi(params) {
    return api.get(`api/student/assignments`, params);
}

function getMyCourseListApi(params) {
    return api.get(`api/student/recent-course`, params);
}

function getHistoryPoint(params) {
    return api.get(`api/student/histories`, params);
}


export { getDashboardDataApi, getMyCourseListApi, getMyAssignmentsApi,getHistoryPoint };
