// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FileTypes } from './constants';
import { responseSuccess, responseError } from './actions';
import {
  getFileUrlApi,
} from '../../helpers/api/fileApi';

function* getFileUrlSaga({ payload: { params } }) {
  try {
    const response = yield call(getFileUrlApi, params);

    if (response.status === 200) {
      const data = response.data ? response.data : null;
      yield put(responseSuccess(FileTypes.GET_FILE_URL, data));
    }
  } catch (error) {
    yield put(responseError(FileTypes.GET_FILE_URL, error));
  }
}

function* fileSaga() {
  yield all([
    takeLatest(FileTypes.GET_FILE_URL, getFileUrlSaga),
  ]);
}

export default fileSaga;
