// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import examSaga from './exam/saga';

import homeSaga from './home/saga';
import dashboardSaga from './dashboard/saga';
import roadmapSaga from './roadmap/saga';
import moduleSaga from './module/saga';
import courseSaga from './course/saga';
import materialSaga from './material/saga';
import assignmentSaga from './assignment/saga';
import fileSaga from './file/saga';
import attendanceSaga from './attendance/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        examSaga(),
        homeSaga(),
        dashboardSaga(),
        roadmapSaga(),
        moduleSaga(),
        courseSaga(),
        materialSaga(),
        assignmentSaga(),
        fileSaga(),
        attendanceSaga(),
    ]);
}
