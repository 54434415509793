// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { HomeTypes } from './constants';
import { responseSuccess, responseError } from './actions';
import { getHomeDataApi, getHomeSearch } from '../../helpers/api/homeApi';

function* getHomeDataSaga({ payload: { params } }) {
    try {
        const response = yield call(getHomeDataApi, params);

        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(HomeTypes.GET_HOME_DATA, data));
        }
    } catch (error) {
        yield put(responseError(HomeTypes.GET_HOME_DATA, error));
    }
}

function* getHomeSearchData({ payload: { params } }) {
    try {
        const response = yield call(getHomeSearch, params);

        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(HomeTypes.GET_SEARCH_HOME_DATA, data));
        }
    } catch (error) {
        yield put(responseError(HomeTypes.GET_SEARCH_HOME_DATA, error));
    }
}

function* homeSaga() {
    yield all([
        takeLatest(HomeTypes.GET_HOME_DATA, getHomeDataSaga),
        takeLatest(HomeTypes.GET_SEARCH_HOME_DATA, getHomeSearchData),
    ]);
}

export default homeSaga;
