import {CourseTypes} from './constants';

const initialState = {
    courseList: [],
    courseDetail: {},
    currentPage: 1,
    pageSize: 12,
    currentPageTopic: 1,
    pageSizeTopic: 2,
    totalRecordsTopic: 0,
    totalRecords: 0,
    loading: false,
    error: null,
    showError: false,
    courseUpdate: false,
    exam: {
        exams: [],
    },
    listTopics: {
        data: [],
        current_page: 1,
        last_page: null
        ,
        total: null,
        links: null,
        first_page_url: null,
        last_page_url: null
    },
    fileCurrentOpen: '',
    submitTopicSuccess: null,
    topicDetail: {},
    permissionsStatus: {},
    hasPermission: null,
    modalRatting: false,
    formRatting: {},
    courseForAdmin: {},
    positionOpening: {}
};

export default function courseReducer(state = initialState, action) {
    switch (action.type) {
        case CourseTypes.RESPONSE_SUCCESS: {
            switch (action.payload.actionType) {
                case CourseTypes.GET_COURSE_LIST:
                    return {
                        ...state,
                        courseList: action.payload.data?.data?.courses?.data,
                        currentPage: action.payload.data?.data?.courses?.current_page,
                        pageSize: action.payload.data?.data?.courses?.to,
                        totalRecords: action.payload.data?.data?.courses?.total,
                        loading: false,
                    };
                case CourseTypes.GET_COURSE_DETAIL:
                    return {
                        ...state,
                        courseDetail: action.payload.data?.data?.course,
                        loading: false,
                    };
                case CourseTypes.GET_LIST_EXAM:
                    return {
                        ...state,
                        exam: {
                            exams: action.payload.data?.exams,
                        },
                        loading: false,
                    };

                case CourseTypes.GET_LIST_TOPIC:
                    let topicsOld = state.listTopics.data, topicsNew = action.payload.data?.listTopics.data, arrNew = []
                    arrNew = action.payload.data?.listTopics.data

                    if (topicsOld.length == 0 || action.payload?.data?.isAddNew) {
                        arrNew = topicsNew
                    } else {
                        topicsOld.map(item => {
                            let topicNewIndex = topicsNew.findIndex(itemNew => itemNew.id !== item.id)
                            if (topicNewIndex >= 0) {
                                topicsOld.push(arrNew[topicNewIndex])
                            }
                        })
                        arrNew = topicsOld
                    }

                    return {
                        ...state,
                        listTopics: {
                            data: arrNew,
                            current_page: action.payload.data?.listTopics.current_page,
                            last_page: action.payload.data?.listTopics.last_page,
                            total: action.payload.data?.listTopics.total,
                            links: action.payload.data?.listTopics.links,
                            first_page_url: action.payload.data?.listTopics.first_page_url,
                            last_page_url: action.payload.data?.listTopics.last_page_url,
                        },

                        loading: false,
                    };
                case CourseTypes.GET_TOPIC_DETAIL:
                    return {
                        ...state,
                        topicDetail: {
                            data: action.payload.data?.topicDetail,
                        },
                        loading: false,
                    };
                case CourseTypes.CREATE_TOPIC:
                    let createTopicOld = state.listTopics.data, createTopicNew = action.payload.data?.listTopics.data,
                        topicNew = []
                    topicNew = action.payload.data?.listTopics.data
                    if (!action.payload.data.isAddNew) {
                        topicNew = createTopicOld.concat(createTopicNew)
                    }
                    return {
                        ...state, submitTopicSuccess: true,
                        loading: false,
                        listTopics: {
                            data: topicNew,
                        }

                    };
                case CourseTypes.DELETE_TOPIC:
                    let topicOld = state.listTopics.data
                    let indexItemTopic = topicOld.findIndex(item => item.id == action.payload.data.questionId)
                    if (indexItemTopic > -1) {
                        topicOld.splice(indexItemTopic, 1)
                    }
                    let listTopicNew = topicOld
                    return {
                        ...state,
                        loading: false,
                        listTopics: {data: listTopicNew}
                    };
                case CourseTypes.UPDATE_TOPIC:
                    let idTopic = action.payload.data.params.courseId
                    let topicCurrent = state.listTopics.data
                    let indexCurrent = topicCurrent.findIndex(item => item.id === idTopic)

                    if (indexCurrent > -1) {
                        topicCurrent[indexCurrent].title = action.payload.data.params.title;
                        topicCurrent[indexCurrent].content = action.payload.data.params.content
                    }

                    return {
                        ...state,
                        listTopics: {data: topicCurrent},
                        loading: false,
                    };
                case CourseTypes.CREATE_COMMENT_STUDENT:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.DELETE_COMMENT_STUDENT:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.UPDATE_COMMENT_STUDENT:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.DELETE_TOPIC:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.UPDATE_TOPIC:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.CREATE_COMMENT_STUDENT:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.DELETE_COMMENT_STUDENT: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case CourseTypes.UPDATE_COMMENT_STUDENT:
                    return {
                        ...state,
                        loading: false,
                    };
                case CourseTypes.UPDATE_COURSE: {
                    return {
                        ...state,
                        loading: false,
                        courseUpdate: true,
                    };
                }
                case CourseTypes.CHECK_PERMISSIONS: {
                    return {
                        ...state,
                        permissionsStatus: action?.payload?.data?.data?.course,
                        loading: false,
                        hasPermission: true,
                    };
                }
                case CourseTypes.UPDATE_RATTING: {
                    return {
                        ...state,
                        modalRatting: false,
                        loading: false,
                    };
                }
                case CourseTypes.FORM_RATTING: {
                    return {
                        ...state,
                        modalRatting: false,
                        loading: false,
                    };
                }
                case CourseTypes.COURSE_FOR_ADMIN: {
                    return {
                        ...state,
                        courseForAdmin: action.payload.data.data,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case CourseTypes.RESPONSE_ERROR: {
            switch (action.payload.actionType) {
                case CourseTypes.GET_COURSE_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: false,
                    };
                case CourseTypes.GET_COURSE_DETAIL:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: false,
                    };
                case CourseTypes.GET_LIST_EXAM: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case CourseTypes.GET_LIST_TOPIC: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case CourseTypes.GET_TOPIC_DETAIL: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case CourseTypes.CREATE_TOPIC: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                }
                case CourseTypes.DELETE_TOPIC:
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                case CourseTypes.UPDATE_TOPIC:
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                case CourseTypes.CREATE_COMMENT_STUDENT:
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                case CourseTypes.DELETE_COMMENT_STUDENT:
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                case CourseTypes.UPDATE_COMMENT_STUDENT:
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                    };
                case CourseTypes.UPDATE_COURSE: {
                    return {
                        ...state,
                        error: action?.payload?.error,
                        loading: false,
                        userUpdate: false,
                    };
                }
                case CourseTypes.CHECK_PERMISSIONS:
                    return {
                        ...state,
                        loading: false,
                        error: action?.payload?.error,
                        hasPermission: false,
                    };
                case CourseTypes.FORM_RATTING: {
                    return {
                        ...state,
                        error: 'lỗi hệ thông.',
                        loading: false,
                    };
                }
                case CourseTypes.UPDATE_RATTING: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case CourseTypes.COURSE_FOR_ADMIN: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case CourseTypes.GET_COURSE_DETAIL:
            return {
                ...state,
                loading: true,
            };
        case CourseTypes.GET_COURSE_LIST:
            return {
                ...state,
                loading: true,
            };
        case CourseTypes.RESET_ERROR: {
            return {
                ...state,
                error: null,
                showError: false,
                hasPermission: false,
                submitTopicSuccess: false,
            };
        }
        case CourseTypes.RESET_UPDATE: {
            return {
                ...state,
                courseUpdate: false,
            };
        }
        case CourseTypes.GET_LIST_EXAM: {
            return {
                ...state,
                loading: true,
            };
        }
        case CourseTypes.GET_LIST_TOPIC: {
            return {
                ...state,
                loading: true,
            };
        }
        case CourseTypes.GET_TOPIC_DETAIL: {
            return {
                ...state,
                loading: true,
            };
        }
        case CourseTypes.CREATE_TOPIC: {
            return {
                ...state,
                loading: true,
            };
        }
        case CourseTypes.DELETE_TOPIC:
            return {
                ...state,
                loading: true,
            };
        case CourseTypes.UPDATE_TOPIC:
            return {
                ...state,
                loading: true,
            };
        case CourseTypes.CREATE_COMMENT_STUDENT:
            return {
                ...state,
                loading: false,
            };
        case CourseTypes.DELETE_COMMENT_STUDENT:
            return {
                ...state,
                loading: true,
            };
        case CourseTypes.UPDATE_COMMENT_STUDENT:
            return {
                ...state,
                loading: true,
            };
        case CourseTypes.CHECK_PERMISSIONS: {
            return {
                ...state,
                loading: true
            };
        }
        case CourseTypes.SET_MODAL_RATTING: {
            return {
                ...state,
                modalRatting: action.payload,
            };
        }
        case CourseTypes.FORM_RATTING: {
            return {
                ...state,
                formRatting: action.payload,
                loading: true,
            };
        }
        case CourseTypes.UPDATE_FORM_RATTING: {
            return {
                ...state,
                formRatting: action.payload,
            };
        }
        case CourseTypes.UPDATE_RATTING: {
            return {
                ...state,
                formRatting: action.payload,
                loading: true,
            };
        }
        case CourseTypes.COURSE_FOR_ADMIN: {
            return {
                ...state,
                courseForAdmin: action.payload,
                loading: true,
            };
        }

        default:
            return state;
    }
}
