// @flow
import { APICore } from './apiCore';

const api = new APICore();

function getAssignmentDetailApi(id) {
    const baseUrl = `api/student/assignments`;
    return api.get(`${baseUrl}/${id}`);
}

function createFileAssignments(params) {
    const baseUrl = 'api/student/assignment-files';
    return api.create(`${baseUrl}`, params);
}

function updateFileAssignments(params) {
    const baseUrl = 'api/student/assignment-files';
    return api.update(`${baseUrl}/${params.id}`, params.data);
}

export { getAssignmentDetailApi, createFileAssignments, updateFileAssignments };
