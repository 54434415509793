// @flow
import { APICore } from './apiCore';
// import axios from 'axios';

const api = new APICore();

const baseUrl = 'api/student/search';

function getHomeDataApi(params) {
    return api.get(baseUrl, params);
}
function getHomeSearch(params) {
    return api.get(`api/student/search-home`, params);
}
export { getHomeDataApi, getHomeSearch };
