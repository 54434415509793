// @flow
import { APICore } from './apiCore';

const api = new APICore();

const baseUrl = 'api/student/materials'

function getMaterialListApi(params) {
    return api.get(baseUrl, params);
}

function getMaterialDetailApi(params) {
    return api.get(`${baseUrl}/${params?.id}`);
}

export {
    getMaterialListApi,
    getMaterialDetailApi,
};
