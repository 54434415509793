import { HomeTypes } from './constants';

const initialState = {
    homeData: [],
    loading: false,
    error: null,
    showError: false,
    searchHomeData: [],
    currentPage: 1,
    pageSize: 15,
    totalRecords: 0,
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case HomeTypes.RESPONSE_SUCCESS: {
            switch (action.payload.actionType) {
                case HomeTypes.GET_HOME_DATA:
                    return {
                        ...state,
                        homeData: action.payload.data?.data,
                        loading: false,
                    };
                case HomeTypes.GET_SEARCH_HOME_DATA:
                    return {
                        ...state,
                        searchHomeData: action.payload.data?.data,
                        loading: false,
                        currentPage: action.payload.data?.data?.merged?.current_page,
                        pageSize: action.payload.data?.data?.merged?.to,
                        totalRecords: action.payload.data?.data?.merged?.total,
                    };
                default:
                    return state;
            }
        }
        case HomeTypes.RESPONSE_ERROR: {
            switch (action.payload.actionType) {
                case HomeTypes.GET_HOME_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: false,
                    };
                case HomeTypes.GET_SEARCH_HOME_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                default:
                    return state;
            }
        }
        case HomeTypes.GET_HOME_DATA:
            return {
                ...state,
                loading: true,
            };
        case HomeTypes.GET_SEARCH_HOME_DATA:
            return {
                ...state,
                loading: true,
            };
        case HomeTypes.RESET_ERROR: {
            return {
                ...state,
                error: null,
                showError: false,
            };
        }
        default:
            return state;
    }
}
