// @flow
import { ModuleTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: ModuleTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: ModuleTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get module list
export const getModuleList = (params) => {
    return {
        type: ModuleTypes.GET_MODULE_LIST,
        payload: { params },
    }
};

//action reset error message
export const resetErrorModule = (params) => {
    return {
        type: ModuleTypes.RESET_ERROR,
        payload: { params },
    };
};
