// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { RoadmapTypes } from './constants';
import { responseSuccess, responseError } from './actions';
import {
  getRoadmapListApi,
} from '../../helpers/api/roadmapApi';

function* getRoadmapListSaga({ payload: { params } }) {
  try {
    const response = yield call(getRoadmapListApi, params);

    if (response.status === 200) {
      const data = response.data ? response.data : null;
      yield put(responseSuccess(RoadmapTypes.GET_ROADMAP_LIST, data));
    }
  } catch (error) {
    yield put(responseError(RoadmapTypes.GET_ROADMAP_LIST, error));
  }
}

function* roadmapSaga() {
  yield all([
    takeLatest(RoadmapTypes.GET_ROADMAP_LIST, getRoadmapListSaga),
  ]);
}

export default roadmapSaga;
