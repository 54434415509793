// @flow
import { APICore } from './apiCore';

const api = new APICore();

const baseUrl = 'api/get-file';

function getFileUrlApi(params) {
    return api.get(baseUrl, params);
}

export {
    getFileUrlApi,
};
