// @flow
import { APICore } from './apiCore';

const api = new APICore();

const baseUrl = 'api/student/courses';

function getCourseListApi(params) {
    return api.get(baseUrl, params);
}

function getCourseDetailApi(params) {
    return api.get(`${baseUrl}/${params?.id}`);
}
function listExam(params) {
    return api.get('api/student/list-exam', params);
}
function listTopic(params) {
    return api.get(`api/student/list-topic`, params);
}
function topicDetail(params) {
    return api.get('api/student/get-detail-topic', { params: params });
}
function createTopic(params) {
    return api.create('api/student/create-topic', params);
}
function deleteTopic(params) {
    return api.delete(`api/student/delete-topic/${params.questionId}`);
}
function updateTopic(params) {
    return api.update(`api/student/update-topic/${params.courseId}`, { title: params.title, content: params.content });
}
function createCommentStudent(params) {
    return api.create(`api/student/create-comment-student`, params);
}
function deleteCommentStudent(params) {
    return api.create(`api/student/delete-comment-student/${params}`);
}
function updateCommentStudent(params) {
    return api.update(`api/student/update-comment-student/${params.comment_id}`, { content: params.content });
}
function checkPermissions(id) {
    return api.get(`api/student/learn-course/${id}`);
}
function updateCourse(params) {
    return api.create('api/student/update-complete-file', params);
}
function createRatting(params) {
    return api.create('api/student/material-ratings', params);
}

function updateRatting(params) {
    return api.update('api/student/material-ratings/' + params.id, params);
}

function previewCourseForAdmin(params) {
  return api.get('api/student/preview-course/' + params.course_id,  params);
}

export {
    getCourseListApi,
    getCourseDetailApi,
    listExam,
    listTopic,
    topicDetail,
    createTopic,
    deleteTopic,
    updateTopic,
    createCommentStudent,
    deleteCommentStudent,
    updateCommentStudent,
    checkPermissions,
    updateCourse,
    createRatting,
    updateRatting,
    previewCourseForAdmin
};
