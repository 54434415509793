// @flow
const groupByFields = (array: Array<any>, f: any): Array<any> => {
    /*
    params description :
        f : function which returnf the array of fields
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]
    */
    var groups = {};
    array.forEach((o) => {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};
const notShow = () => {
    let domainWebOnline = process.env["REACT_APP_URL_WEB_ONLINE"]
    let domainCurrent = window.location.href
    return domainCurrent.search(domainWebOnline) !== -1
}

export { groupByFields, notShow };
