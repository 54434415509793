// @flow
import { AttendanceActionTypes } from './constants';

// common success
export const apiResponseSuccess = (actionType, data) => {
    return {
        type: AttendanceActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    };
};
// common error
export const apiResponseError = (actionType, error) => ({
    type: AttendanceActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addReason = (params) => ({
    type: AttendanceActionTypes.ADD_REASON,
    payload: { params },
});

export const getReason = (params) => ({
    type: AttendanceActionTypes.GET_REASON,
    payload: { params },
});

export const deleteReason = (params) => {
    return {
        type: AttendanceActionTypes.DELETE_REASON,
        payload: { params },
    };
};
