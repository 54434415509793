export const AssignmentTypes = {
    RESPONSE_SUCCESS: '@@assignment/RESPONSE_SUCCESS',
    RESPONSE_ERROR: '@@assignment/RESPONSE_ERROR',
    RESET_ERROR: '@@assignment/RESET_ERROR',

    GET_ASSIGNMENT_DETAIL: '@@assignment/GET_ASSIGNMENT_DETAIL',
    UPDATE_ASSIGNMENT_FILE: '@@assignment/UPDATE_ASSIGNMENT_FILE',
    CREATE_ASSIGNMENT_FILE: '@@assignment/CREATE_ASSIGNMENT_FILE',
    RESET: '@@assignment/RESET',
};
