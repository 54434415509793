import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { ROLES } from '../constants/lsm';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/log-in/Login'));
const Register = React.lazy(() => import('../pages/account/register/index'));
const VerifyEmail = React.lazy(() => import('../pages/account/register/verifyEmail'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const ForgotPassword = React.lazy(() => import('../pages/account/fotgot-password/ForgotPassword'));
const Confirm = React.lazy(() => import('../pages/account/confirm/Confirm'));
const ChangePassword = React.lazy(() => import('../pages/account/change-password/ChangePassword'));
const Information = React.lazy(() => import('../pages/account/information/Information'));
const Exam = React.lazy(() => import('../pages/exam/Exam'));
const ExamDetail = React.lazy(() => import('../pages/exam/ExamDetail'));
const ExamResult = React.lazy(() => import('../pages/exam/ExamResult'));
const qa = React.lazy(() => import('../pages/learn/qa'));
const AssignmentDetail = React.lazy(() => import('../pages/assignment/AssignmentDetail'));
const ExamHistory = React.lazy(() => import('../pages/exam/ExamHistory'));
const SearchHome = React.lazy(() => import('../pages/home/Search'));
const ChangePasswordAuth = React.lazy(() => import('../pages/dashboard/change-password/index'));

const PreviewCourseForAdmin = React.lazy(() => import('../pages/account/coursePassAll'));

//Home
const Home = React.lazy(() => import('../pages/home/Home'));
//Roadmap list
const RoadmapList = React.lazy(() => import('../pages/roadmap-list/RoadmapList'));
//Module list
const ModuleList = React.lazy(() => import('../pages/module-list/ModuleList'));
//Course list
const CourseList = React.lazy(() => import('../pages/course-list/CourseList'));
//Course detail
const CourseDetail = React.lazy(() => import('../pages/course-detail/CourseDetail'));
//Learn
const Learn = React.lazy(() => import('../pages/learn/Learn'));
const DetailCourse = React.lazy(() => import('../pages/learn'));
//Post
const PostDetail = React.lazy(() => import('../pages/post/PostDetails'));
const PostList = React.lazy(() => import('../pages/post/PostList'));
//Tools
const ToolsDetail = React.lazy(() => import('../pages/tools/ToolsDetails'));
const ToolsList = React.lazy(() => import('../pages/tools/ToolsList'));

//Dashboard
const DashboardOld = React.lazy(() => import('../pages/dashboard/DashboardOld'));
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));

//My Roadmap Detail
const MyRoadmapDetail = React.lazy(() => import('../pages/dashboard/my-roadmap-detail/MyRoadmapDetail'));
const MyCourseList = React.lazy(() => import('../pages/dashboard/my-course-list/MyCourseList'));
const MyAssignmentList = React.lazy(() => import('../pages/dashboard/my-assignment-list/MyAssignmentList'));
// airtable
const FeedbackAirTable = React.lazy(() => import('../pages/aritable/feedback'));
//Page error
const Notfound = React.lazy(() => import('../pages/pageError/404'));
const Authorized = React.lazy(() => import('../pages/pageError/403'));

const isHiddenWebOnline = () => {
    let domainWebOnline = process.env['REACT_APP_URL_WEB_ONLINE '];
    let domainCurrent = window.location.href;
    return domainCurrent.search(domainWebOnline) === -1;
};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: Home,
    // route: PrivateRoute,
    route: Route,
};

// dashboards
const dashboardRoutes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        exact: true,
        // route: PrivateRoute,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },

    {
        path: '/roadmap-list',
        name: 'RoadmapList',
        component: RoadmapList,
        isHidden: isHiddenWebOnline(),
        exact: true,
        // route: PrivateRoute,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },

    {
        path: '/module-list',
        name: 'ModuleList',
        exact: true,
        component: ModuleList,
        // route: PrivateRoute,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '/change-password-auth',
        name: 'changePasswordAuth',
        exact: true,
        component: ChangePasswordAuth,
        // route: PrivateRoute,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '/course-list',
        name: 'CourseList',
        exact: true,
        component: CourseList,
        // route: PrivateRoute,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '/course-detail/:id',
        name: 'CourseDetail',
        exact: true,
        component: CourseDetail,
        // route: PrivateRoute,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '/dashboard-old',
        name: 'DashboardOld',
        component: DashboardOld,
        route: PrivateRoute,
        // route: Route,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        route: PrivateRoute,
        // route: Route,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/my-roadmap-detail',
        isHidden: isHiddenWebOnline(),
        name: 'MyRoadmapDetail',
        component: MyRoadmapDetail,
        route: PrivateRoute,
        // route: Route,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/account',
        name: 'Account',
        component: Information,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/exam',
        name: 'exam',
        component: Exam,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/exam-detail',
        name: 'examDetail',
        component: ExamDetail,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/exam-result',
        name: 'examResult',
        component: ExamResult,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/qa',
        name: 'qa',
        component: qa,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/assignment-detail/:id',
        name: 'assignmentDetail',
        component: AssignmentDetail,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/exam-history',
        name: 'examHistory',
        component: ExamHistory,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/learn/:id',
        name: 'Learn',
        component: DetailCourse,
        route: PrivateRoute,
        // route: Route,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/detailLearn/:id',
        name: 'detailLearn',
        component: DetailCourse,
        route: PrivateRoute,
        // route: Route,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/search/:keyword',
        name: 'SearchHome',
        component: SearchHome,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '/my-course-list',
        name: 'MyCourseList',
        component: MyCourseList,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/feedback',
        name: 'FeedbackAirTable',
        component: FeedbackAirTable,
        exact: true,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/assignment-list',
        name: 'MyAssignmentList',
        component: MyAssignmentList,
        route: PrivateRoute,
        roles: [ROLES.MEMBER],
    },
    {
        path: '/verify-mail',
        name: 'VerifyEmail',
        component: VerifyEmail,
        route: Route,
    },
    {
        path: '/authorized',
        name: 'authorized',
        component: Authorized,
        route: Route,
    },
    {
        path: '/posts',
        name: 'PostList',
        component: PostList,
        route: Route,
    },
    {
        path: '/post-detail/:id/:slug',
        name: 'PostDetail',
        component: PostDetail,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '/tools',
        name: 'ToolsList',
        component: ToolsList,
        route: Route,
    },
    {
        path: '/tools-detail/:id/:slug',
        name: 'ToolsDetail',
        component: ToolsDetail,
        route: Route,
        roles: [ROLES.CUSTOMER, ROLES.MEMBER],
    },
    {
        path: '*',
        name: 'notfound',
        component: Notfound,
        route: Route,
    },
];

const otherPublicRoutes = [
    // {
    //     path: '/error-404',
    //     name: 'Error - 404',
    //     component: ErrorPageNotFound,
    //     route: Route,
    // },
    // {
    //     path: '/error-500',
    //     name: 'Error - 500',
    //     component: ServerError,
    //     route: Route,
    // },
    // {
    //     path: '/account/confirm',
    //     name: 'Confirm',
    //     component: Confirm,
    //     route: Route,
    // },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];
    routes = routes || [];

    routes.forEach((item) => {
        flatRoutes.push(item);
        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};
// auth
const authRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/forgot-password',
        name: 'Forget Password',
        component: ForgotPassword,
        route: Route,
    },
    {
        path: '/account/confirm',
        name: 'confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/reset-password',
        name: 'changepass',
        component: ChangePassword,
        route: Route,
    },
    {
        path: '/account/information',
        name: 'information',
        component: Information,
        route: Route,
    },
    {
        path: '/preview-course',
        name: 'previewCourse',
        component: PreviewCourseForAdmin,
        route: Route,
    },
];

// All routes
const authProtectedRoutes = [rootRoute, ...dashboardRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
