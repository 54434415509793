// @flow
import React, { Suspense } from 'react';

// actions
import HeaderSider from '../header-sider/HeaderSider';

const loading = () => <div className="text-center"></div>;

const HorizontalLayout = ({ children }, state) => {
    return (
        <>
            <HeaderSider>
                <Suspense fallback={loading()}>{children}</Suspense>
            </HeaderSider>
        </>
    );
};

export default HorizontalLayout;
