import axios from 'axios';

import config from '../../config';

const AUTH_SESSION_KEY = 'hyper_user';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
if (window.location.href.search(process.env.REACT_APP_WEB_URL_PRODUCT) !== -1) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL_PRODUCT
} else if (window.location.href.search(process.env.REACT_APP_URL_WEB_ONLINE) !== -1) {
    axios.defaults.baseURL = process.env.REACT_APP_API_WEB_ONLINE;
} else {
    axios.defaults.baseURL = config.API_URL;
}

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 404) {
           return  window.location.href = '/404';
        } else {
            switch (error.response.status) {
                case 401:
                    // message = 'Invalid credentials';
                    message = 'Thông tin không hợp lệ';
                    localStorage.removeItem(AUTH_SESSION_KEY);
                    // window.location.href = '/authorized';
                    break;
                case 403:
                    // message = 'Access Forbidden';
                    message = 'Truy cập bị cấm';
                    // localStorage.removeItem(AUTH_SESSION_KEY);
                    window.location.href = '/authorized';
                    break;
                case 404:
                    message = 'Xin lỗi! không thể tìm thấy dữ liệu bạn đang tìm kiếm';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    const hyperUser = JSON.parse(localStorage.getItem(AUTH_SESSION_KEY));
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + hyperUser.token;
    else delete axios.defaults.headers.common['Authorization'];
    axios.defaults.headers.post['Content-Type'] = 'application/json';
};

const getUserFromSession = () => {
    const user = localStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, {responseType: 'blob'});
        } else {
            response = axios.get(`${url}`, {responseType: 'blob'});
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axios.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();
        if (!user || (user && !user.token)) {
            return false;
        }
        return true;
    };

    setLoggedInUser = (session) => {
        if (session) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const {token, user} = JSON.parse(userInfo);
            this.setLoggedInUser({token, ...user, ...modifiedUser});
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
    const {token} = user;
    if (token) {
        setAuthorization(token);
    }
}

export {APICore, setAuthorization};
