// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { MaterialTypes } from './constants';
import { responseSuccess, responseError } from './actions';
import {
  getMaterialListApi,
  getMaterialDetailApi,
} from '../../helpers/api/materialApi';

function* getMaterialListSaga({ payload: { params } }) {
  try {
    const response = yield call(getMaterialListApi, params);

    if (response.status === 200) {
      const data = response.data ? response.data : null;
      yield put(responseSuccess(MaterialTypes.GET_MATERIAL_LIST, data));
    }
  } catch (error) {
    yield put(responseError(MaterialTypes.GET_MATERIAL_LIST, error));
  }
}

function* getMaterialDetailSaga({ payload: { params } }) {
  try {
    const response = yield call(getMaterialDetailApi, params);

    if (response.status === 200) {
      const data = response.data ? response.data : null;
      yield put(responseSuccess(MaterialTypes.GET_MATERIAL_DETAIL, data));
    }
  } catch (error) {
    yield put(responseError(MaterialTypes.GET_MATERIAL_DETAIL, error));
  }
}

function* materialSaga() {
  yield all([
    takeLatest(MaterialTypes.GET_MATERIAL_LIST, getMaterialListSaga),
    takeLatest(MaterialTypes.GET_MATERIAL_DETAIL, getMaterialDetailSaga),
  ]);
}

export default materialSaga;
