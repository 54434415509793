// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Exam from './exam/reducers';
import Attendance from './attendance/reducers';

import Home from './home/reducers';
import Dashboard from './dashboard/reducers';
import Assignment from './assignment/reducers';
import Roadmap from './roadmap/reducers';
import Module from './module/reducers';
import Course from './course/reducers';
import Material from './material/reducers';
import File from './file/reducers';

export default combineReducers({
    Auth,
    Layout,
    Exam,
    Home,
    Dashboard,
    Assignment,
    Roadmap,
    Module,
    Course,
    Material,
    File,
    Attendance,
});
