import React from 'react';
import ReactDOM from 'react-dom';
import viVN from 'antd/lib/locale/vi_VN'
import App from './App';

import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

ConfigProvider.config({
    theme: {
        primaryColor: '#BB2327',
    },
});
ReactDOM.render(
    <ConfigProvider locale={viVN}>
        <Provider store={configureStore({})}>
            <App />
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);
