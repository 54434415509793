import { FileTypes } from './constants';

const initialState = {
    fileUrl: '',
    loading: false,
    error: null,
    showError: false,
};

export default function fileReducer(state = initialState, action) {
    switch (action.type) {
        case FileTypes.RESPONSE_SUCCESS: {
            switch (action.payload.actionType) {
                case FileTypes.GET_FILE_URL:
                    return {
                        ...state,
                        fileUrl: action.payload.data?.data?.fileURL,
                        loading: false,
                    };

                default:
                    return state;
            }
        }
        case FileTypes.RESPONSE_ERROR: {
            switch (action.payload.actionType) {
                case FileTypes.GET_FILE_URL:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: false,
                    };

                default:
                    return state;
            }
        }
        case FileTypes.GET_FILE_URL:
            return {
                ...state,
                loading: true,
            };
        case FileTypes.RESET_ERROR: {
            return {
                ...state,
                error: null,
                showError: false,
            };
        }
        default:
            return state;
    }
}
