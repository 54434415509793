// @flow
import { APICore } from './apiCore';

const api = new APICore();

const baseUrl = 'api/student';

function isCheckinUser() {
    return api.get(`${baseUrl}/get-roll-call-status`);
}

function submitAttendanceForm() {
    return api.create(`${baseUrl}/roll-call`);
}

function submitReasonForm(params) {
    return api.create(`${baseUrl}/off-learn-reasons`, params);
}

function updateReason(params) {
    const { id: _, ...newParams } = params;
    return api.update(`${baseUrl}/off-learn-reasons/${params.id}`, newParams);
}

function getReason(params) {
    return api.get(`${baseUrl}/off-learn-reasons`,params);
}

function deleteReason(id) {
    return api.delete(`${baseUrl}/off-learn-reasons/${id}`);
}

function getHistoryAttendance(params) {
    return api.get(`${baseUrl}/roll-call-reports`,params);
}

export {
    submitAttendanceForm,
    submitReasonForm,
    isCheckinUser,
    getReason,
    deleteReason,
    updateReason,
    getHistoryAttendance,
};
