// @flow
import { RoadmapTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: RoadmapTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: RoadmapTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get roadmap list
export const getRoadmapList = (params) => {
    return {
        type: RoadmapTypes.GET_ROADMAP_LIST,
        payload: { params },
    }
};

//action reset error message
export const resetErrorRoadmap = (params) => {
    return {
        type: RoadmapTypes.RESET_ERROR,
        payload: { params },
    };
};
