// @flow
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { DashboardTypes } from './constants';
import { responseSuccess, responseError } from './actions';
import { getDashboardDataApi, getMyCourseListApi, getMyAssignmentsApi } from '../../helpers/api/dashboardApi';

function* getDashboardDataSaga({ payload: { params } }) {
    try {
        const response = yield call(getDashboardDataApi, params);
        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(DashboardTypes.GET_DASHBOARD_DATA, data));
        }
    } catch (error) {
        yield put(responseError(DashboardTypes.GET_DASHBOARD_DATA, error));
    }
}

function* getMyCourseList({ payload: { params } }) {
    try {
        const response = yield call(getMyCourseListApi, params);
        if (response.status === 200) {
            const data = response.data ? response.data : null;
            yield put(responseSuccess(DashboardTypes.GET_MY_COURSE_LIST, data));
        }
    } catch (error) {
        yield put(responseError(DashboardTypes.GET_MY_COURSE_LIST, error));
    }
}

function* getMyAssignment({ payload: { params } }) {
    try {
        const response = yield call(getMyAssignmentsApi, params);
        if (response.status === 200) {
            const data = response.data;
            yield put(responseSuccess(DashboardTypes.GET_MY_ASSIGNMENTS, data));
        }
    } catch (error) {
        yield put(responseError(DashboardTypes.GET_MY_ASSIGNMENTS, error));
    }
}

function* dashboardSaga() {
    yield all([
        takeLatest(DashboardTypes.GET_DASHBOARD_DATA, getDashboardDataSaga),
        takeLatest(DashboardTypes.GET_MY_COURSE_LIST, getMyCourseList),
        takeLatest(DashboardTypes.GET_MY_ASSIGNMENTS, getMyAssignment),
    ]);
}

export default dashboardSaga;
