import { DashboardTypes } from './constants';

const initialState = {
    dashboardData: [],
    loading: false,
    error: null,
    showError: false,
    myCourse: [],
    myAssignment: [],
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DashboardTypes.RESPONSE_SUCCESS: {
            switch (action.payload.actionType) {
                case DashboardTypes.GET_DASHBOARD_DATA:
                    return {
                        ...state,
                        dashboardData: action.payload.data?.data,
                        loading: false,
                    };
                case DashboardTypes.GET_MY_COURSE_LIST:
                    return {
                        ...state,
                        myCourse: action.payload.data?.data,
                        loading: false,
                    };
                case DashboardTypes.GET_MY_ASSIGNMENTS:
                    return {
                        ...state,
                        myAssignment: action.payload.data?.data?.assignments,
                        loading: false,
                    };
                default:
                    return state;
            }
        }
        case DashboardTypes.RESPONSE_ERROR: {
            switch (action.payload.actionType) {
                case DashboardTypes.GET_DASHBOARD_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                        showError: true,
                        loading: true,
                    };
                case DashboardTypes.GET_MY_COURSE_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: true,
                    };
                case DashboardTypes.GET_MY_ASSIGNMENTS:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: true,
                    };
                default:
                    return state;
            }
        }
        case DashboardTypes.GET_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
            };
        case DashboardTypes.GET_MY_COURSE_LIST:
            return {
                ...state,
                loading: true,
            };
        case DashboardTypes.GET_MY_ASSIGNMENTS:
            return {
                ...state,
                loading: true,
            };
        case DashboardTypes.RESET_ERROR: {
            return {
                ...state,
                error: null,
                showError: false,
            };
        }
        default:
            return state;
    }
}
