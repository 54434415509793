// @flow
import { CourseTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: CourseTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: CourseTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get course list
export const getCourseList = (params) => {
    return {
        type: CourseTypes.GET_COURSE_LIST,
        payload: { params },
    };
};

// action get course detail
export const getCourseDetail = (params) => {
    return {
        type: CourseTypes.GET_COURSE_DETAIL,
        payload: { params },
    };
};

//action reset error message
export const resetErrorCourse = () => {
    return {
        type: CourseTypes.RESET_ERROR,
    };
};

export const getListExam = (params) => {
    return {
        type: CourseTypes.GET_LIST_EXAM,
        payload: { params },
    };
};
export const getListTopic = (params) => {
    return {
        type: CourseTypes.GET_LIST_TOPIC,
        payload: { params },
    };
};


export const getTopicDetail = (params) => {
    return {
        type: CourseTypes.GET_TOPIC_DETAIL,
        payload: { params },
    };
};
export const createTopic = (params) => {
    return {
        type: CourseTypes.CREATE_TOPIC,
        payload: { params },
    };
};
export const deleteTopic = (params) => {
    return {
        type: CourseTypes.DELETE_TOPIC,
        payload: { params },
    };
};
export const updateTopic = (data) => {
    return {
        type: CourseTypes.UPDATE_TOPIC,
        payload: data,
    };
};
export const createCommentStudent = (params) => {
    return {
        type: CourseTypes.CREATE_COMMENT_STUDENT,
        payload: params,
    };
};
export const deleteCommentStudent = (params) => {
    return {
        type: CourseTypes.DELETE_COMMENT_STUDENT,
        payload: params,
    };
};
export const updateCommentStudent = (params) => {
    return {
        type: CourseTypes.UPDATE_COMMENT_STUDENT,
        payload: params,
    };
};

export const checkPermissions = (params) => {
    return {
        type: CourseTypes.CHECK_PERMISSIONS,
        payload: { params },
    };
};
export const updateCourse = (data): AuthAction => ({
    type: CourseTypes.UPDATE_COURSE,
    payload: { data },
});

export const resetUpdateCourse = () => {
    return {
        type: CourseTypes.RESET_UPDATE,
    };
};
export const setModalRatting = (data) => {
    return {
        type: CourseTypes.SET_MODAL_RATTING,
        payload: data,
    };
};
export const setFormRatting = (data) => {
    return {
        type: CourseTypes.FORM_RATTING,
        payload: data,
    };
};
export const updateFormRatting = (data) => {
    return {
        type: CourseTypes.UPDATE_FORM_RATTING,
        payload: data,
    };
};
export const updateRatting = (data) => {
    return {
        type: CourseTypes.UPDATE_RATTING,
        payload: data,
    };
};

export const getCourseForAdmin = (data) => {
    return {
        type: CourseTypes.COURSE_FOR_ADMIN,
        payload: data,
    };
};
