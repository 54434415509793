// @flow
import { FileTypes } from './constants';

// common success
export const responseSuccess = (actionType, data) => ({
    type: FileTypes.RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const responseError = (actionType, error) => ({
    type: FileTypes.RESPONSE_ERROR,
    payload: { actionType, error },
});

// action get file url
export const getFileUrl = (params) => {
    return {
        type: FileTypes.GET_FILE_URL,
        payload: { params },
    }
};

//action reset error message
export const resetErrorFile = (params) => {
    return {
        type: FileTypes.RESET_ERROR,
        payload: { params },
    };
};
