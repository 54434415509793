export const CourseTypes = {
    RESPONSE_SUCCESS: '@@course/RESPONSE_SUCCESS',
    RESPONSE_ERROR: '@@course/RESPONSE_ERROR',
    RESET_ERROR: '@@course/RESET_ERROR',

    GET_COURSE_LIST: '@@course/GET_COURSE_LIST',
    GET_COURSE_DETAIL: '@@course/GET_COURSE_DETAIL',
    GET_LIST_EXAM: '@@course/GET_LIST_EXAM',
    GET_LIST_TOPIC: '@@course/GET_LIST_TOPIC',
    GET_TOPIC_DETAIL: '@@course/GET_TOPIC_DETAIL',
    CREATE_TOPIC: '@@course/CREATE_TOPIC',
    DELETE_TOPIC: '@@course/DELETE_TOPIC',
    UPDATE_TOPIC: '@@course/UPDATE_TOPIC',
    CREATE_COMMENT_STUDENT: '@@course/CREATE_COMMENT_STUDENT',
    DELETE_COMMENT_STUDENT: '@@course/DELETE_COMMENT_STUDENT',
    UPDATE_COMMENT_STUDENT: '@@course/UPDATE_COMMENT_STUDENT',
    CHECK_PERMISSIONS: '@@course/CHECK_PERMISSIONS',
    UPDATE_COURSE: '@@auth/UPDATE_COURSE',
    RESET_UPDATE: '@@auth/RESET_UPDATE',
    SET_MODAL_RATTING: '@@course/SET_MODAL_RATTING',
    FORM_RATTING: '@@course/FORM_RATTING',
    UPDATE_FORM_RATTING: '@@course/UPDATE_FORM_RATTING',
    UPDATE_RATTING: '@@course/UPDATE_RATTING',
    COURSE_FOR_ADMIN: '@@course/COURSE_FOR_ADMIN',
};
